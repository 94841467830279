
<template>
    <div class="PointsStore">
        <div class="router">
            <div class="center connectBoxW">
                <router-link to="/Points" tag="a" ><i class="el-icon-arrow-left"></i> Back to POINTS</router-link>
            </div>
        </div>
        <!-- <p class="router">
            <a href="">Points ></a>
            <span> Points Store</span>
        </p> -->
        <div class="banner">
            <div class="center connectBoxW">
                <div class="bigTitle">
                    <h1>Points Store</h1>
                    <p class="test">Redeeming Space Points for Prize: Planet NFT, {{xtokenSymbol}}, <br />Airdrop, WL, etc.</p>
                    <div class="banner1">
                        <img src="../assets/Points/banner1.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="introduce">
            <div class="center connectBoxW">
                <div class="title">First Points Redemption Event</div>
                <div class="text">5000 Points - 1 NFT Planet (limited to 3 copies, max 1 redemption per address)</div>
                <div class="text">300 Points - 50{{xtokenSymbol}} (limited to 100 copies, max 3 redemptions per address)</div>
            </div>
        </div>
        <div class="infodata">
            <div class="center connectBoxW">
                <h1 class="yourPoints">
                    <img src="../assets/Points/yourPoints.png" alt="">
                    Your Points: 0.000
                </h1>
                <div class="infobox">
                    <div class="card">
                        <div class="imgbox">
                            <img src="../assets/Points/planetnftItem.png" alt="">
                            <p>3/3 SOLD</p>
                        </div>
                        <div class="info">
                            <h1>Planet NFT</h1>
                            <p class="redeemedNum">redeemed: 0</p>
                            <div class="infodiv">
                                <p>5000<span>Points</span></p>
                                <p>
                                    <span><i class="el-icon-minus"></i></span>
                                    <span>1</span>
                                    <span><i class="el-icon-plus"></i></span>
                                </p>
                            </div>
                            <div class="redeemedBtn">Redeem</div>
                            <p class="tip">max 1 redemption per address</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="imgbox">
                            <img src="../assets/Points/xspace.png" alt="">
                            <p>1/100 SOLD</p>
                        </div>
                        <div class="info">
                            <h1>50 {{xtokenSymbol}}</h1>
                            <p class="redeemedNum">redeemed: 0</p>
                            <div class="infodiv">
                                <p>300<span>Points</span></p>
                                <p>
                                    <span><i class="el-icon-minus"></i></span>
                                    <span>1</span>
                                    <span><i class="el-icon-plus"></i></span>
                                </p>
                            </div>
                            <div class="redeemedBtn">Redeem</div>
                            <p class="tip">max 3 redemptions per address</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-Footer></v-Footer>
    </div>
</template>

<script>
export default {
    name: "PointsStore",
    data() {
        return {
			tokenSymbol: '',xtokenSymbol: '',
        }
    },
    created() { },
    mounted() { 
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
    },
    methods: {

    }
}
</script>